//// TEST
//// sub.jsファイルを読み込む
// import {hello} from "./sub";
//// sub.jsに定義されたJavaScriptを実行する
// hello();

// You can specify which plugins you need
import { Tooltip, Toast, Popover } from "bootstrap";


const ua = navigator.userAgent.toLowerCase();
const isMobile = /iphone/.test(ua)||/android(.+)?mobile/.test(ua);
const $breakpoint = 992;
var isTouchDevice = (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch);
var eventType = (isTouchDevice) ? 'touchend' : 'click';

//-----------------------------------------------
// Menu
//-----------------------------------------------

document.addEventListener('DOMContentLoaded', () => {
	const $navbarBurger = document.querySelector('#navbar-burger');
	const $navItem = document.querySelectorAll('#navbar-global ul.nav a');
	const target = $navbarBurger.dataset.target;
	const $target = document.getElementById(target);
	const $body = document.body;

	if ($navbarBurger) {
		$navbarBurger.addEventListener(eventType, () => {
			$navbarBurger.classList.toggle('is-active');
			$target.classList.toggle('is-active');
			$body.classList.toggle('is-fixed');
		});
	}
	if($navItem.length > 0) {
		$navItem.forEach( el => {
			el.addEventListener(eventType, (e) => {
				e.preventDefault();
				const targetId = el.hash;
				const targetElement = document.querySelector(targetId);
				const targetOffsetTop = window.scrollY + targetElement.getBoundingClientRect().top;

				if($navbarBurger.classList.contains('is-active')) {
					$navbarBurger.classList.remove('is-active');
					$target.classList.remove('is-active');
					$body.classList.remove('is-fixed');
				}
				window.scrollTo({
					top: targetOffsetTop,
					behavior: "smooth"
				});
			});
		});
	}
});

const dropDownNav = () => {
	const $dropDownMenu = document.querySelectorAll('.dropdown-menu');
	const $dropdownToggle = document.querySelectorAll('.dropdown-toggle');

	if ( window.innerWidth < $breakpoint ) {
		if ($dropDownMenu.length > 0) {
			$dropDownMenu.forEach( el => {
				el.classList.add('show');
			});
		}
		if ($dropdownToggle.length > 0) {
			$dropdownToggle.forEach( el => {
				eventType = eventType + '.bs.dropdown.data-api';
				el.addEventListener(eventType, (e) => {
					e.stopPropagation();
				});
			});
		}
	} else {
		if ($dropDownMenu.length > 0) {
			$dropDownMenu.forEach( el => {
				if( el.classList.contains('show') ) {
					el.classList.remove('show');	
				}
			});
		}
	}
	
}

//-----------------------------------------------
// To call
//-----------------------------------------------
const toCall = () => {
	var tocalls = document.getElementsByClassName('phone');

	for (var i=0; i<tocalls.length; i++) {
		var tocall = tocalls[i];

		if (tocall.getAttribute("href") && (tocall.getAttribute("rel") == "callto")) {
			tocall.onclick = function(e) {
				e.preventDefault();
			};
		}
	}
}
if(!isMobile ) toCall();

//-----------------------------------------------
// Image Copy Guard
//-----------------------------------------------
const copyGuard = () => {
	const $imagePhotos = Array.prototype.slice.call(document.querySelectorAll('img'), 0);

	if ($imagePhotos.length > 0) {

		$imagePhotos.forEach( el => {
			var eventType = 'contextmenu oncopy';	
				
			el.addEventListener(eventType, () => {
				return false;
			});
			el.addEventListener('dragstart', (e) => {
				e.stopPropagation();
				e.preventDefault();
			});
		})
	}
}

//-----------------------------------------------
// KeyVisual
//-----------------------------------------------
const kvSwitch = () => {
	const pc_kv = document.querySelector('#kv-pc');
	const sp_kv = document.querySelector('#kv-sp');

	if( isMobile ) {
		if( sp_kv.classList.contains('d-none') ) {
			sp_kv.classList.remove('sd-none');	
		}
		pc_kv.classList.add('d-none');
	} else {
		if( pc_kv.classList.contains('d-none') ) {
			pc_kv.classList.remove('sd-none');	
		}
		sp_kv.classList.add('d-none');
	}
}


//-----------------------------------------------

window.addEventListener('load', () => {
	copyGuard();
	dropDownNav();
	kvSwitch();
});
window.addEventListener('resize', () => {
	dropDownNav();
});